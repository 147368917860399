<template>
  <vuestic-modal
    v-bind:large="true"
    ref="largeModalEdictIten"
    okClass="none"
    cancelClass="none"
  >
    <div slot="title">{{relato.item_nome}}</div>

    <div class="container">
      <div class="row">
        <div class="col">
          <div class="form-group">

            <label class="select-control-label">Tipo do Relato</label>
            <multiselect
              v-model="tiposRelatosSelecionado"
              placeholder="Selecione"
              track-by="id"
              label="nome"
              :options="tiposRelatos"
              selectedLabel="Selecionado"
              select-label="Selecione uma tipo"
              deselect-label="Remover tipo"
            >
              <template slot="noOptions">Sem Tipos</template>
              <span slot="noResult">Tipo não encontrado.</span>
            </multiselect>
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label class="select-control-label">Grupo do Relato</label>
            <multiselect
              placeholder="Selecione"
              v-model="grupoRelatoSelecionado"
              track-by="id"
              label="nome"
              :options="novoGrupoRelato"
              selectedLabel="Selecionado"
              select-label="Selecione uma tipo"
              deselect-label="Remover tipo"
            >
              <template slot="noOptions">Sem Grupos</template>
              <span slot="noResult">Grupo não encontrado.</span>
            </multiselect>
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label class="select-control-label">Item do Relato</label>
            <multiselect
              placeholder="Selecione"
              track-by="id"
              v-model="itemRelatoSelecionado"
              label="nome"
              :options="novoItemRelato"
              selectedLabel="Selecionado"
              select-label="Selecione uma item"
              deselect-label="Remover item"
            >
              <template slot="noOptions">Sem Itens</template>
              <span slot="noResult">Item não encontrado.</span>
            </multiselect>
          </div>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <button
          align="center"
          class="btn btn-primary btn-micro"
          type="button"
          :disabled="!itemRelatoSelecionado.id"
          @click="mudarTipo()"
        >Alterar</button>
      </div>
    </div>
  </vuestic-modal>
</template>

<script>
export default {
  props: {
    relato: {
      type: Object,
    },
    itemRelato: {
      type: Array,
      default: () => [],
    },
    grupoRelato: {
      type: Array,
      default: () => [],
    },
    tiposRelatos: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tiposRelatosSelecionado: {},
      grupoRelatoSelecionado: {},
      itemRelatoSelecionado: {},
    };
  },

  methods: {
    open() {
      this.popularCampos();
      this.$refs.largeModalEdictIten.open();
    },
    async popularCampos() {
      const { item } = this.relato;
      const itemRelato = await this.itemRelato.find(i => i.id === item);

      const { grupo } = itemRelato;
      const grupoRelato = await this.grupoRelato.find(g => g.id === grupo);

      const { tipo } = grupoRelato;
      const tiposRelato = await this.tiposRelatos.find(t => t.id === tipo);

      this.tiposRelatosSelecionado = await tiposRelato;
      this.grupoRelatoSelecionado = await grupoRelato;
      this.itemRelatoSelecionado = await itemRelato;
    },
    mudarTipo() {
      this.$emit('mudarItem', this.itemRelatoSelecionado);
      this.$refs.largeModalEdictIten.close();
    },
  },
  computed: {
    novoGrupoRelato() {
      return this.tiposRelatosSelecionado
        ? this.grupoRelato.filter(g => g.tipo === this.tiposRelatosSelecionado.id) : [];
    },
    novoItemRelato() {
      return this.grupoRelatoSelecionado
        ? this.itemRelato.filter(i => i.grupo === this.grupoRelatoSelecionado.id) : [];
    },
  },
  watch: {
    tiposRelatosSelecionado() {
      this.grupoRelatoSelecionado = {};
    },
    grupoRelatoSelecionado() {
      this.itemRelatoSelecionado = {};
    },

  },
};
</script>

<style>
</style>
